
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import ByMonth from "@/views/report/child-revenue/ByMonths.vue";
import Indicator from "@/views/report/child-revenue/Indicator.vue";
import Category from "@/views/report/child-revenue/Category.vue";
import Detail from "@/views/report/child-revenue/Detail.vue";

import { useStore } from "vuex";
import { ReportActions } from "@/store/vm/report/enums";

export default defineComponent({
  name: "report-common",
  components: {
    ByMonth,
    Indicator,
    Category,
    Detail,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const stats = ref({});

    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 6);
    const startDateInput = ref(startDate.toISOString().substring(0, 10)); // 6 months
    const endDateInput = ref(new Date().toISOString().substring(0, 10));

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Bảng tin", ["Doanh thu"]);
      MenuComponent.reinitialization();

      await getReport();
    });

    const getReport = async () => {
      isLoading.value = true;
      const res = await store
        .dispatch(ReportActions.GET_REVENUE_REPORT, {
          startDate: startDateInput.value,
          endDate: endDateInput.value,
        })
        .catch((e) => {
          console.log(e);
        });
      stats.value = {
        revenue: res.revenue.result,
        startDate: startDateInput.value,
        endDate: endDateInput.value,
      };
      isLoading.value = false;
    };

    return {
      startDateInput,
      endDateInput,
      stats,
      isLoading,
      getReport,
    };
  },
});
