
import { defineComponent } from "vue";
import { numberWithCommas } from "@/core/helpers/common";

export default defineComponent({
  name: "revenue-indicator",
  props: {
    stats: Object,
  },
  components: {},
  setup(props) {
    const enrol = props.stats?.revenue as {
      id: number;
      class_id: number;
      student_id: number;
      status: number;
      created_at: string;
      first_name: string;
      last_name: string;
      code: string;
      class_name: string;
      price: number;
      course_id: number;
      course_name: string;
    }[];

    const classes: number[] = [];
    const course: number[] = [];
    const numberStudent = enrol.length;
    let totalRevenue = 0;

    enrol.forEach((m) => {
      if (!classes.includes(m.class_id)) {
        classes.push(m.class_id);
      }
      if (!course.includes(m.course_id)) {
        course.push(m.course_id);
      }
      totalRevenue += m.price;
    });

    return {
      classes,
      course,
      numberStudent,
      totalRevenue,
      numberWithCommas,
    };
  },
});
