
import { defineComponent } from "vue";
import { numberWithCommas } from "@/core/helpers/common";

export default defineComponent({
  name: "revenue-category",
  props: {
    stats: Object,
  },
  components: {},
  setup(props) {
    const enrol = props.stats?.revenue as {
      id: number;
      class_id: number;
      student_id: number;
      status: number;
      created_at: string;
      first_name: string;
      last_name: string;
      code: string;
      class_name: string;
      price: number;
      course_id: number;
      course_name: string;
    }[];

    const categoryByClass: Record<
      string,
      {
        class_id: number;
        class_name: string;
        course_id: number;
        course_name: string;
        code: string;
        price: number;
        numberStudent: number;
        totalRevenue: number;
      }
    > = {};

    const categoryByCourse: Record<
      string,
      {
        course_id: number;
        course_name: string;
        price: number;
        numberStudent: number;
        totalRevenue: number;
      }
    > = {};

    enrol.forEach((m) => {
      const classId = m.class_id;
      if (!categoryByClass[classId]) {
        categoryByClass[classId] = {
          class_id: m.class_id,
          class_name: m.class_name,
          course_id: m.course_id,
          course_name: m.course_name,
          code: m.code,
          price: m.price,
          numberStudent: 0,
          totalRevenue: 0,
        };
      }

      categoryByClass[classId].numberStudent += 1;
      categoryByClass[classId].totalRevenue += m.price;

      const courseId = m.course_id;
      if (!categoryByCourse[courseId]) {
        categoryByCourse[courseId] = {
          course_id: m.course_id,
          course_name: m.course_name,
          price: m.price,
          numberStudent: 0,
          totalRevenue: 0,
        };
      }
      categoryByCourse[courseId].numberStudent += 1;
      categoryByCourse[courseId].totalRevenue += m.price;
    });

    return {
      categoryByClass,
      categoryByCourse,
      numberWithCommas,
    };
  },
});
