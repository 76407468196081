
import { defineComponent } from "vue";

export default defineComponent({
  name: "revenue-by-month",
  props: {
    stats: Object,
  },
  components: {},
  setup(props) {
    const enrol = props.stats?.revenue as {
      id: number;
      class_id: number;
      student_id: number;
      status: number;
      created_at: string;
      first_name: string;
      last_name: string;
      code: string;
      class_name: string;
      price: number;
      course_id: number;
      course_name: string;
    }[];

    const revenueByMonth: Record<string, number> = {};

    const startDate = new Date(props.stats?.startDate);
    const endDate = new Date(props.stats?.endDate);

    while (startDate <= endDate) {
      const month = startDate.getMonth() + 1;
      const year = startDate.getFullYear();
      const k = `${month}-${year}`;
      revenueByMonth[k] = 0;
      startDate.setMonth(startDate.getMonth() + 1);
    }

    enrol.forEach((m) => {
      const month = new Date(m.created_at).getMonth() + 1;
      const year = new Date(m.created_at).getFullYear();
      const k = `${month}-${year}`;
      if (revenueByMonth[k]) {
        revenueByMonth[k] += m.price;
      } else {
        revenueByMonth[k] = m.price;
      }
    });

    const options = {
      chart: {
        height: 450,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Doanh thu",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: Object.keys(revenueByMonth),
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          },
        },
      },
    };

    const series = [
      {
        name: "Doanh thu",
        data: Object.values(revenueByMonth),
      },
    ];
    return {
      options,
      series,
    };
  },
});
