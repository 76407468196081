
import { defineComponent, ref } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { numberWithCommas } from "@/core/helpers/common";

export default defineComponent({
  name: "revenue-detail",
  props: {
    stats: Object,
  },
  components: { KTDatatable },
  setup(props) {
    const enrol = props.stats?.revenue as {
      id: number;
      class_id: number;
      student_id: number;
      status: number;
      created_at: string;
      first_name: string;
      last_name: string;
      code: string;
      class_name: string;
      price: number;
      course_id: number;
      course_name: string;
    }[];

    const tableData = ref(enrol);

    const tableHeader = ref([
      {
        name: "User ID",
        key: "student_id",
        sortable: true,
      },
      {
        name: "Mã lớp",
        key: "code",
        sortable: false,
      },
      {
        name: "Lớp học",
        key: "class_name",
        sortable: false,
      },
      {
        name: "Khóa học",
        key: "course_name",
        sortable: false,
      },
      {
        name: "Họ",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Tên",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Giá",
        key: "price",
        sortable: true,
      },

      {
        name: "Ngày đăng ký",
        key: "created_at",
        sortable: false,
      },
    ]);

    return {
      tableHeader,
      tableData,
      numberWithCommas,
    };
  },
});
